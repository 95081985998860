import React, { useState } from 'react';

const BackArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M19 12H5M12 19l-7-7 7-7"/>
  </svg>
);

const BurgerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
);

const TopNav = ({ logo, showBackButton, onBackClick, title, onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-blue-600 p-4 sticky top-0 z-50 shadow-md">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {showBackButton && (
            <button
              onClick={onBackClick}
              className="mr-4 text-white hover:text-gray-200"
              aria-label="Go back"
            >
              <BackArrow />
            </button>
          )}
          {true == false && logo && (
            <img src={logo} alt="Logo" className="h-8 w-auto" />
          )}
        </div>
        {title && (
          <h1 className="text-white text-lg font-semibold">{title}</h1>
        )}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white hover:text-gray-200 z-10"
          aria-label="Toggle menu"
        >
          <BurgerIcon />
        </button>
      </div>
      {isMenuOpen && (
        <div className="absolute top-full right-0 mt-2 mr-4 bg-white rounded-md shadow-lg">
          <button
            onClick={() => {
              onLogout();
              setIsMenuOpen(false);
            }}
            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            Log out
          </button>
        </div>
      )}
    </nav>
  );
};

export default TopNav;